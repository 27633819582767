<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-form>
      <v-card>
        <!-- begin::Modal title -->
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar ubicación de código</span>
        </v-toolbar>
        <!-- end::Modal title -->

        <!-- begin::modal content and fields -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="nombre" :validator="$v.codePlace.name">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Tipo de código"
                    placeholder="Ej.: Ex-aula"
                    required
                    rounded
                    filled
                    v-model="codePlace.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group
                  name="descripción"
                  :validator="$v.codePlace.description"
                >
                  <v-textarea
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Descripción"
                    placeholder="Ej.: Faltas con este nivel de gravedad conllevan..."
                    required
                    rounded
                    filled
                    v-model="codePlace.description"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- end::modal content and fields -->

        <!-- begin::actions -->
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="amber" depressed :loading="isLoading" @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
        <!-- end::actions -->
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import codePlaceRepository from "@/repositories/codePlaceRepository";
import { alpha } from "@/core/plugins/vuelidate";

export default {
  name: "UpdateCodePlaceModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },

  props: {
    codePlace: {
      type: Object,
    },
  },

  methods: {
    update() {
      this.isLoading = true;
      codePlaceRepository
        .updateCodePlace(this.codePlace.id, this.codePlace)
        .then(({ data }) => {
          // Showing alert with response data
          this.fireToast({
            icon: "success",
            title: data.message,
          });

          // Retrieving data and hidding modal if operation has been completed
          this.$emit("refresh");
          this.closeModal();
        })
        .catch((error) => {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            this.fireToast({
              icon: "error",
              title: "No ha sido posible crear el registro",
            });
            return;
          }
          if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            this.fireToast({
              icon: "error",
              title: "Ocurrió un error al comunicarse con el servidor",
            });
            return;
          }
          // Something happened in setting up the request and triggered an Error
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
    },
  },
  validations: {
    codePlace: {
      name: {
        required,
        maxLength: maxLength(255),
        alpha,
      },
      description: {
        required,
        maxLength: maxLength(255),
        alpha,
      },
    },
  },
};
</script>
